import React from "react"
import { useThemeUI } from "theme-ui"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import BarChart from "../../../../../components/bar-chart"
import Count from "../../../../../components/count"
import Small from "../../../../../components/small"
import MarkerHighlight from "../../../../../components/marker-highlight"
import Paragraph from "../../../../../components/paragraph"

const Page = () => {
  const context = useThemeUI()
  const { theme } = context

  const chartData = [
    { id: 20, color: theme.colors["chart"], name: "Welt", value: 94 },
    { id: 19, color: theme.colors["chart"], name: "Mann", value: 95 },
    { id: 18, color: theme.colors["primary"], name: "Gesang", value: 96 },
    { id: 17, color: theme.colors["chart"], name: "Kind", value: 113 },
    { id: 16, color: theme.colors["primary"], name: "Natur", value: 114 },
    { id: 15, color: theme.colors["chart"], name: "Nacht", value: 118 },
    { id: 14, color: theme.colors["chart"], name: "Auge", value: 131 },
    { id: 13, color: theme.colors["primary"], name: "Freude", value: 136 },
    { id: 12, color: theme.colors["chart"], name: "Vater", value: 160 },
    { id: 11, color: theme.colors["primary"], name: "Zeit", value: 181 },
    { id: 10, color: theme.colors["chart"], name: "Erde", value: 184 },
    { id: 9, color: theme.colors["chart"], name: "Himmel", value: 191 },
    { id: 8, color: theme.colors["chart"], name: "Liebe", value: 193 },
    { id: 7, color: theme.colors["primary"], name: "Geist", value: 203 },
    { id: 6, color: theme.colors["chart"], name: "Seele", value: 206 },
    { id: 5, color: theme.colors["chart"], name: "Herz", value: 218 },
    { id: 4, color: theme.colors["chart"], name: "Tag", value: 260 },
    { id: 3, color: theme.colors["primary"], name: "Leben", value: 261 },
    { id: 2, color: theme.colors["primary"], name: "Mensch", value: 274 },
    { id: 1, color: theme.colors["chart"], name: "Gott/Götter", value: 351 },
  ]

  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          label="Weiter"
          to="/kurse/jahreszeiten/01-jahreszeitengedicht/adjektive"
        />
      }
    >
      <Seo title="Hölderlin und die Jahreszeiten" />
      <Stack>
        <Paragraph>
          Das sind ganz schön viele! Von den <Count>74</Count> Worten des
          Gedichts sind <Count>24</Count> Nomen. Einige davon gehören übrigens
          zu den häufigsten Nomen in Hölderlins Gedichten:{" "}
          <MarkerHighlight>
            Mensch, Leben, Geist, Zeit, Freude, Natur und Gesang
          </MarkerHighlight>
          .
        </Paragraph>
        <Stack space={6}>
          <BarChart
            height="700px"
            data={chartData}
            colors={({ id, data }) => data["color"]}
            layout="horizontal"
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            isInteractive={false}
            enableGridY={false}
            margin={{ top: 0, right: 60, bottom: 0, left: 80 }}
            indexBy="name"
          />
          <Small color="whisper">
            Hölderlins 20 häufigste Nomen, gezählt von Heike Gfrereis, Vera
            Hildebrandt und Roland Kamzelak für die Ausstellung ›Hölderlin,
            Celan und die Sprachen der Poesie‹. Daten aus dem Deutschen
            Literaturarchiv Marbach.
          </Small>
        </Stack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
